
.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
}

.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
    line-height: 18px !important;
    padding-top: 8px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
/*.text-strike {
    border-bottom: 1px solid red !important;
}*/
.rm-text-strike {
    border-bottom-color: black !important;
}
/*.MuiIconButton-colorInherit[title = 'Cancel'] {    
    display: none !important;
}*/

.validationErrorMsg {
    color: red !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

body {
    overflow-x: hidden;
}

.fileimprt {
    padding-left: 13px !important;
    display:none;
}

.comp-substance {
    position: relative;
    left: 118px;
}

.simple-select {
    width: 150px;
}

.search-box {
    min-width: 500px;
}
.search-box-cf {
    min-width: 422px;
}
.iframe {
    overflow: hidden;
    min-height: 500px;
    width: 100%
}

tbody.MuiTableBody-root tr:first-child {
    /*display: none;*/
}

tbody.MuiTableBody-root.domainClass tr:first-child {
    display: block !important;
    left: 96px;
    position: relative;
}
tbody.MuiTableBody-root.namelistBody tr:first-child {
    display: table-row !important;
    /*left: 96px;*/
    position: relative;
}
tbody.MuiTableBody-root.compositelistBody tr:first-child {
    display: table-row !important;
    /*left: 96px;*/
    position: relative;
}
tbody.MuiTableBody-root.compositetabcontentBody tr:first-child {
    display: table-row !important;
    /*left: 96px;*/
    position: relative;
}
.auditDesc {
    width: 100%;
}

#demo-mutiple-chip .MuiInputBase-input {
    padding: 0px !important;
}
.MuiBackdrop-root{
    z-index: 0 !important;
}
.note{
    font-size: 14px;
    color:red;
}
.marTop {
    margin-top: -2px !important;
}
.MuiSlider-thumb {
    width: 12px !important;
    height: 12px !important;
  }
.MuiButton-root{
    min-width: 51px !important;
}

.MuiButton-root.Mui-disabled > .MuiSvgIcon-root{
    color : inherit !important;
}
.MuiButton-root.Mui-disabled > .fa{
    color : inherit !important;
}
.MuiButton-root.arrowDropdownIcon > .MuiSvgIcon-root{
    color : white !important;
}
.MuiButton-root > .MuiSvgIcon-root{
    color : black !important;
}

.MuiButton-root > .fa{
    color : black !important;
}
.MuiDialog-paperWidthLg {
    max-width: 1280px !important;
}
.MuiTab-wrapped {
    line-height: 1.5 !important;
}
#domain > .MuiFormControlLabel-label {
    font-size: 0.75rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 400 !important;
    line-height: 1.66 !important;
    letter-spacing: 0.03333em !important;
}
    /*.disableSearch{
    pointer-events: none;
    background-color: grey;
}*/


/*responsive header*/
.header {
    display:flex;
    justify-content: space-between;
    box-shadow: -1px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.head{
    display:flex;
    padding-left:24px;
}
.title {
    /*font-size: 2.125rem;*/
    /*font-size: calc(19px + (32 - 14) * ((100vw - 300px) / (1600 - 300)));*/
    font-size: calc(19px + (32 - 14) * ((100vw - 500px) / (1600 - 500)));
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
    color: #0E0E9A;
    padding-top: 5px;
}
.userDetails {
    padding-right:24px;

}
.loginLogout {
    display: flex;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    color: #0E0E9A;
}
.role {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    color: #0E0E9A;
}
@media only screen and (min-width: 595px) and (max-width: 850px) {
    .title {
        font-size: calc(19px + (32 - 14) * ((100vw - 500px) / (1600 - 500)));
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.235;
        letter-spacing: 0.00735em;
        color: #0E0E9A;
        padding-top: 8px;
    }
}
@media (min-width: 600px) {
    .MuiTab-root {
        min-width: unset !important;
    }
    /* min-width: 160px; */

}

@media only screen and (min-width: 595px) and (max-width: 869px) {
    .set-width {
        width: 250px;
    }

    .set-width-email {
        width: 470px;
    }
    .table {
        min-width: 100% !important;
    }
    tbody.MuiTableBody-root.domainClass tr:first-child {
        display: block !important;
        left: 42px !important;
        position: relative;
    }
    .noFlex{
        display: unset !important;
    }
    .centerAligned {
        text-align: center !important;
        width: unset !important;
    }
    .fullWidth{
        width:100% !important;
    }

}
@media only screen and (min-width: 870px) {
    .set-width {
        width: 250px;
    }

    .set-width-email {
        width: 575px;
    }
    /* min-width: 160px; */

}
@media only screen and (max-width: 1256px) {
    .comp-substance {
        left:unset !important;
    }

   
    /* min-width: 160px; */

}
@media only screen and (max-width: 1256px) {
    .comp-substance {
        left: unset !important;
    }


    /* min-width: 160px; */

}
/*
.MuiTable-root {
    overflow: auto;
}*/