.MuiAppBar-colorPrimary {
    background-color: white !important;
    color: black !important;
}

.MuiTab-root {
    margin-right: 6px !important;
    border: 1px solid #333 !important;
    border-radius: 2px !important;
    min-height: 40px !important;
}
#simple-tab-0, #simple-tab-1, #simple-tab-2, #simple-tab-3, #simple-tab-4 {
    padding: 6px 8px !important;
}
.MuiPaper-elevation4 {
    box-shadow: none !important;
}

.PrivateTabIndicator-colorSecondary-354 {
    display: none !important;
}

.PrivateTabIndicator-colorSecondary-353 {
    display: none !important;
}

.PrivateTabIndicator-colorSecondary-50 {
    display: none !important;
}

.MuiTab-textColorInherit.Mui-selected {
    opacity: 1;
    color: blue;
}

.PrivateTabIndicator-colorSecondary-70 {
    display: none !important;
}

.PrivateTabIndicator-colorSecondary-120 {
    display: none !important;
}

.MuiTabs-indicator {
    display: none !important;
}

#simple-tab-4 {
    margin-right: 0px !important;
    /*display: none;*/
}

@media (min-width: 960px) {
    .MuiTab-root {
        font-size: 14px !important;
        /*min-width: 100px !important;*/
    }
}

@media only screen and (min-width: 969px) and (max-width: 1024px) {
    .MuiTab-root {
        font-size: 12px !important;
        /*min-width: 100px !important;*/
    }
}
    .form-control {
        display: block;
        /*width: 100%;*/
        height: 20px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .makeStyles-paper-5 {
        text-align: unset !important;
    }

    .marBtm {
        margin-bottom: 10px;
    }

    .btn-primary {
        color: #fff;
        background-color: #337ab7;
        border-color: #2e6da4;
        display: inline-block;
        margin-bottom: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        border-radius: 4px;
    }

    .PrivateTabIndicator-colorSecondary-70 {
        display: none !important;
    }

    #demo-mutiple-chip .MuiInputBase-input {
        padding: 0px !important;
    }